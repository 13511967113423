<template>
  <div class="page-error text-center">
    <h1 class="primary">您的账户已停用</h1>
    <p class="desc">该账户禁用一切功能，如有问题请联系管理员</p>
    <div><el-image :src="img"></el-image></div>
  </div>
</template>

<script>
import img from "@/assets/img/unpower.png";
export default {
  data() {
    return { img };
  },
};
</script>

<style lang="scss" scoped>
.desc {
  font-size: 18px;
  font-weight: 300;
}
</style>
